<template>
  <div>
    <form-wizard
      @on-complete="doSubmitFinish"
      :start-index="0"
      color="#393939"
      error-color="#ff4949"
      step-size="xs"
      ref="formWizard"
      title=""
      subtitle=""
    >
      <tab-content icon="ti-user">
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="form"
          v-if="model"
          :disabled="isCompleted"
        >
          <!-- Template to download gantt chart template-->
          <app-template-view-file-page
            templateType="gantt"
          ></app-template-view-file-page>

          <el-form-item
            :label="fields.ganttChart.label"
            :prop="fields.ganttChart.name"
            :required="fields.ganttChart.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.ganttChart.max"
                :storage="fields.ganttChart.storage"
                :formats="fields.ganttChart.formats"
                :permissions="fields.ganttChart.permissions"
                v-model="model[fields.ganttChart.name]"
              ></app-file-upload>
            </el-col>
            <el-col :lg="3" :md="3" :sm="3">
              <app-work-package-autocomplete-input
                :fetchFn="fields.workPackage.fetchFn"
                :mapperFn="fields.workPackage.mapperFn"
                :showCreate="!modal"
                :parentId="model.id"
                v-model="model[fields.workPackage.name]"
                mode="multiple"
                :showSelectElement="false"
              ></app-work-package-autocomplete-input>
            </el-col>
          </el-form-item>

          <app-work-package-list-table
            v-bind:isInPojectGantt="true"
          ></app-work-package-list-table>
        </el-form>
      </tab-content>

      <!-- Wzard Buttons -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-right">
          <div class="wizard-btn">
            <wizard-button
              v-if="!isCompleted"
              icon="el-icon-fa-floppy-o"
              @click.native="doSubmit"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.save"></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              @click.native="doSubmitFinish()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              v-if="props.isLastStep && !(saveLoading || isCompleted)"
              ><app-i18n code="common.finish"></app-i18n
            ></wizard-button>
          </div>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectGanttModel } from '@/modules/project-gantt/project-gantt-model';
import WorkPackageListTable from '@/modules/work-package/components/work-package-list-table';
import Templates from '@/modules/template/components/template-view-file-page';
import { EventBus } from '@/eventBus';

const { fields } = ProjectGanttModel;
const formSchema = new FormSchema([
  fields.id,
  fields.workPackage,
  fields.ganttChart,
  fields.status,
  fields.updatedAt,
]);

export default {
  name: 'app-project-gantt-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  components: {
    [Templates.name]: Templates,
    [WorkPackageListTable.name]: WorkPackageListTable,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  watch: {
    'model.workPackage': function(payload) {
      // when the parentId prop changes, this function will be fired.
      EventBus.$emit('updateWorkPackageList', payload);
    },
    model: {
      deep: true,
      handler: function() {
        this.$store.dispatch(
          'projectGantt/form/doSetFormModel',
          this.model,
        );
      },
    },
    record: function() {
      this.doUpdateModel();
    },
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },

    isCompleted() {
      return this.model.status === 'completed';
    },
  },

  methods: {
    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },

    async doSubmitFinish() {
      this.model.status = 'completed';
      this.doSubmit();
    },
  },
};
</script>

<style></style>
