<template>
  <div>
    <el-table
      :border="!isInPojectGantt"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="!isInPojectGantt"
      ></el-table-column>

      <el-table-column
        :label="fields.name.label"
        :prop="fields.name.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'name')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.milestone.label"
        :prop="fields.milestone.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'milestone')
        }}</template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="180"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <el-button
              type="text"
              @click="doOpenModal(scope.row)"
              v-if="hasPermissionToEdit"
            >
              <app-i18n code="common.edit"></app-i18n>
            </el-button>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
    <portal to="modal">
      <app-work-package-form-modal
        :visible="dialogVisible"
        :record="record"
        v-if="dialogVisible"
        @success="onModalSuccess"
        @close="onModalClose"
      ></app-work-package-form-modal>
    </portal>
  </div>
</template>

<script>
import { WorkPackageModel } from '@/modules/work-package/work-package-model';
import { mapGetters, mapActions } from 'vuex';
import { WorkPackagePermissions } from '@/modules/work-package/work-package-permissions';
import WorkPackageFormModal from '@/modules/work-package/components/work-package-form-modal';
import { i18n } from '@/i18n';
import { EventBus } from '@/eventBus';

const { fields } = WorkPackageModel;

export default {
  name: 'app-work-package-list-table',

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  props: ['isInPojectGantt', 'releaseMode'],
  
  components: {
    [WorkPackageFormModal.name]: WorkPackageFormModal,
  },

  data() {
    return {
      dialogVisible: false,
      record: null,
    };
  },

  computed: {
    ...mapGetters({
      rows: 'workPackage/list/rows',
      count: 'workPackage/list/count',
      loading: 'workPackage/list/loading',
      pagination: 'workPackage/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'workPackage/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new WorkPackagePermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new WorkPackagePermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'workPackage/list/doChangeSort',
      doChangePaginationCurrentPage:
        'workPackage/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'workPackage/list/doChangePaginationPageSize',
      doMountTable: 'workPackage/list/doMountTable',
      doDestroy: 'workPackage/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return WorkPackageModel.presenter(row, fieldName);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },

    doOpenModal(payload) {
      this.record = payload;
      this.dialogVisible = true;
    },

    onModalSuccess() {
      this.dialogVisible = false;
      EventBus.$emit('updateWorkPackageList');
    },

    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>
