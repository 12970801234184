<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="findLoading"
      v-loading="findLoading"
    ></div>

    <app-project-gantt-form
      :isEditing="isEditing"
      :record="record"
      :saveLoading="saveLoading"
      @submit="doSubmit"
      v-if="isDataReady"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GanttForm from '@/modules/project-gantt/components/project-gantt-form';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { i18n } from '@/i18n';
import { EventBus } from '@/eventBus';
import ProjectStepEnum from '@/shared/enums/projectStep';

export default {
  name: 'app-project-gantt-form-page',

  components: {
    [GanttForm.name]: GanttForm,
  },

  data() {
    return {
      projectStepEnum: ProjectStepEnum.values,
    };
  },

  computed: {
    ...mapGetters({
      record: 'projectGantt/form/record',
      findLoading: 'projectGantt/form/findLoading',
      saveLoading: 'projectGantt/form/saveLoading',
      pendingFiles: 'projectGantt/form/pendingFiles',
      status: 'projectGantt/form/status',
      projectGanttId: 'project/form/projectGanttId',
    }),

    isDataReady() {
      return this.record && !this.findLoading;
    },

    isEditing() {
      return !!this.projectGanttId;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.projectGanttId);
    } else {
      await this.doNew();
      await this.doSubmit({
        id: undefined,
        values: {
          status: 'inProgress',
        },
      });
    }

    await this.doFilterWorkPackagesList();

    EventBus.$on('updateWorkPackageList', (payload) => {
      this.doFilterWorkPackagesList(payload);
    });
  },

  async beforeRouteLeave(to, from, next) {
    //if status is completed we skip the dialog check and navigate to next page
    if (this.status === 'completed' || to.name.includes('error')) {
      next();
      return;
    }

    try {
      await this.$confirm(
        i18n('common.discardFormConfirm'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        },
      );

      let response;
      if (this.pendingFiles.length > 0) {
        response = await FileUploader.removePendingFiles(
          this.pendingFiles,
        );
        if (response === 200) next();
      }

      next();
    } catch (error) {
      next(false);
    }
  },

  beforeDestroy() {
    EventBus.$off('updateWorkPackageList');
  },

  methods: {
    ...mapActions({
      doFind: 'projectGantt/form/doFind',
      doNew: 'projectGantt/form/doNew',
      doUpdate: 'projectGantt/form/doUpdate',
      doCreate: 'projectGantt/form/doCreate',
      doFetchWorkPackages: 'workPackage/list/doFetch',
      doUpdateProjectStep: 'project/form/doUpdateProjectStep',
      doSetProjectGanttId: 'project/form/doSetProjectGanttId',
      doUpdateProject: 'project/form/doUpdate',
      doReloadProject: 'project/form/doReload',
    }),

    async doSubmit(payload) {
      if (this.isEditing) {
        const response = await this.doUpdate(payload);
        if (response) {
          if (response.status === 'completed') {
            /* await this.doUpdateProjectStep(
              this.projectStepEnum.implementation,
            ); */
            await this.navigateToProjectImplementation();
            await this.doReloadProject();
          }
        }
      } else {
        const id = await this.doCreate(payload.values);
        this.doSetProjectGanttId(id);
        this.doUpdateProject();
      }
    },

    navigateToProjectImplementation() {
      EventBus.$emit('navigateToProjectImplementation');
    },

    async doFilterWorkPackagesList(payload) {
      const filter = this.setFilter(payload);

      return this.doFetchWorkPackages({
        filter,
      });
    },

    setFilter(payload) {
      let id;
      if (payload != undefined || payload != null) {
        id = payload.map(function (f) {
          return f.id;
        });
      }

      return {
        projectGantt: this.projectGanttId,
        id,
      };
    },
  },
};
</script>

<style>
</style>
